<template>
  <div class="tinymce-container" :style="{ width: containerWidth }">
    <textarea :id="tinymceId" visibility="hidden" ref="elRef"></textarea>
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  nextTick,
  ref,
  unref,
  watch,
  onUnmounted,
  onDeactivated
} from "vue";
import { basicProps } from "./props";
import toolbar from "./toolbar";
import plugins from "./plugins";
import { snowUuid } from "@wlhy-web-lib/shared";
import { bindHandlers, getTinymce } from "./helper";
import { useScript } from "@/hooks/UseScript";

const CDN_URL = "https://cdn.bootcdn.net/ajax/libs/tinymce/5.5.1";

const tinymceScriptSrc = `${CDN_URL}/tinymce.min.js`;

export default {
  name: "Tinymce",
  props: basicProps,
  emits: ["change", "update:value"],
  setup(props, { emit, attrs }) {
    const editorRef = ref(null);
    const elRef = ref(null);

    const tinymceId = computed(() => {
      return snowUuid("tiny-vue");
    });

    const tinymceContent = computed(() => {
      return props.value;
    });

    const containerWidth = computed(() => {
      const width = props.width;
      if (/^[\d]+(\.[\d]+)?$/.test(width.toString())) {
        return `${width}px`;
      }
      return width;
    });

    const initOptions = computed(() => {
      const { height, menubar, useToolbar, usePlugins } = props;
      return {
        selector: `#${unref(tinymceId)}`,
        height: height,
        toolbar: useToolbar || toolbar,
        theme: "silver",
        menubar: menubar,
        plugins: usePlugins || plugins,
        // 语言包
        language_url:
          "https://lab.uxfeel.com/node_modules/tinymce/langs/zh_CN.js",
        // 中文
        language: "zh_CN",
        default_link_target: "_blank",
        link_title: false,
        advlist_bullet_styles: "square",
        advlist_number_styles: "default",
        object_resizing: false,
        setup: editor => {
          editorRef.value = editor;
          editor.on("init", e => initSetup(e));
        }
      };
    });

    const { toPromise } = useScript({
      src: tinymceScriptSrc
    });

    watch(
      () => attrs.disabled,
      () => {
        const editor = unref(editorRef);
        if (!editor) return;
        editor.setMode(attrs.disabled ? "readonly" : "design");
      }
    );

    onMounted(() => {
      nextTick(() => {
        init();
      });
    });

    onUnmounted(() => {
      destory();
    });

    onDeactivated(() => {
      destory();
    });

    function destory() {
      if (getTinymce() !== null) {
        getTinymce().remove(unref(editorRef));
      }
    }

    function init() {
      toPromise().then(() => {
        initEditor();
      });
    }

    function initEditor() {
      getTinymce().init(unref(initOptions));
    }

    function initSetup(e) {
      const editor = unref(editorRef);
      if (!editor) return;
      const value = props.value || "";
      editor.setContent(value);
      bindModelHandlers(editor);
      bindHandlers(e, attrs, unref(editorRef));
    }

    function bindModelHandlers(editor) {
      const modelEvents = attrs.modelEvents ? attrs.modelEvents : null;
      const normalizedEvents = Array.isArray(modelEvents)
        ? modelEvents.join(" ")
        : modelEvents;
      watch(
        () => props.value,
        (val, prevVal) => {
          if (
            editor &&
            typeof val === "string" &&
            val !== prevVal &&
            val !== editor.getContent({ format: attrs.outputFormat })
          ) {
            editor.setContent(val);
          }
        }
      );

      editor.on(
        normalizedEvents ? normalizedEvents : "change keyup undo redo",
        e => {
          emit(e.type, editor.getContent({ format: attrs.outputFormat }));
        }
      );
    }

    return {
      containerWidth,
      initOptions,
      tinymceContent,
      tinymceScriptSrc,
      elRef,
      tinymceId
    };
  }
};
</script>

<style lang="less" scoped>
.tinymce-container {
  position: relative;
  line-height: normal;

  .mce-fullscreen {
    z-index: 10000;
  }
}

.editor-custom-btn-container {
  position: absolute;
  top: 6px;
  right: 6px;

  &.fullscreen {
    position: fixed;
    z-index: 10000;
  }
}

.editor-upload-btn {
  display: inline-block;
}

textarea {
  z-index: -1;
  visibility: hidden;
}
</style>
