<template>
  <div class="page-main-box" id="addTaxSource">
    <div class="title">
      <a
        ><Icon name="icontianjiashuiyuandiguanbi" @click="$router.back()"></Icon
      ></a>
      <span class="fz-16 ml-16 fw-600">{{
        $route.query.id ? "修改合同模板" : "新建合同模板"
      }}</span>
    </div>

    <div class="content mt-16">
      <Form
        ref="formRef"
        :model="formState"
        :rules="rules"
        class="form-box"
        layout="vertical"
      >
        <div class="form-content">
          <div class="left">
            <!-- 第一步 -->
            <div v-if="step === 1">
              <FormItem label="合同模板名称" name="name">
                <p class="input-grey" v-if="$route.query.id">
                  {{ formState.name }}
                </p>
                <a-input
                  v-else
                  v-model:value="formState.name"
                  @change="handleNameChange"
                  placeholder="请输入模板名称"
                  style="width:320px;"
                />
              </FormItem>

              <FormItem label="未签署时登录触发展示" name="frequency">
                <a-select
                  v-model:value="formState.frequency"
                  placeholder="请选择"
                  style="width:320px;"
                >
                  <a-select-option value="ONCE">仅一次</a-select-option>
                  <a-select-option value="EVERY_TIME">每次</a-select-option>
                  <a-select-option value="DAILY">每天一次</a-select-option>
                  <a-select-option value="WEEKLY">每周一次</a-select-option>
                </a-select>
              </FormItem>
              <FormItem label="生效时间">
                <RadioGroup
                  name="radioGroup"
                  v-model:value="formState.time_type"
                >
                  <Radio value="1">即刻生效</Radio>
                  <Radio value="2">定时生效</Radio>
                </RadioGroup>
              </FormItem>
              <FormItem
                label="选择时间"
                v-if="formState.time_type === '2'"
                name="effective_time"
              >
                <DatePicker
                  v-model:value="formState.effective_time"
                  :disabled-date="disabledRangeTime"
                  format="YYYY-MM-DD HH:mm:ss"
                  show-time
                  type="date"
                  style="width:240px;"
                />
              </FormItem>
              <FormItem label="合同编码" name="code">
                <a-input
                  v-model:value="formState.code"
                  placeholder="请输入模板编码"
                  style="width:320px;"
                />
              </FormItem>
            </div>

            <!-- 第二步 -->
            <div v-if="step === 2">
              <p>占位符名称：</p>
              <a-button class="mt-16" @click="handleAddPlaceHolder">
                添加占位符
              </a-button>

              <ul class="placeholder-list">
                <li
                  class="mt-24"
                  v-for="(item, value) in formState.placeholder_array"
                  :key="value"
                >
                  <a-input
                    v-model:value="item.key"
                    placeholder="请输入占位符字段"
                  ></a-input>
                  <Icon name="iconhetongzhanweifuqiehuan" class="icon"></Icon>
                  <a-input
                    v-model:value="item.value"
                    placeholder="请输入占位符名称"
                  ></a-input>
                </li>
              </ul>
            </div>

            <!-- 第三步 -->
            <div v-if="step === 3">
              <FormItem label="签署对象" name="signed_object">
                <a-select
                  v-model:value="formState.signed_object"
                  placeholder="请选择"
                  style="width:320px;"
                >
                  <a-select-option value="BOTH">双方签章</a-select-option>
                  <a-select-option value="COMPANY">企业签章</a-select-option>
                  <a-select-option value="PLATFORM">平台签章</a-select-option>
                </a-select>
              </FormItem>
              <FormItem label="甲方盖章区域关键字" name="first_keyword">
                <a-input
                  v-model:value="formState.first_keyword"
                  placeholder="请输入"
                  style="width:320px;"
                />
              </FormItem>
              <FormItem label="X轴偏移量（px）">
                <a-input
                  v-model:value="formState.first_posx"
                  placeholder="请输入"
                  style="width:320px;"
                />
              </FormItem>
              <FormItem label="Y轴偏移量（px）">
                <a-input
                  v-model:value="formState.first_posy"
                  placeholder="请输入"
                  style="width:320px;"
                />
              </FormItem>
              <div v-if="formState.signed_object === 'BOTH'">
                <FormItem label="乙方盖章区域关键字" name="second_keyword">
                  <a-input
                    v-model:value="formState.second_keyword"
                    placeholder="请输入"
                    style="width:320px;"
                  />
                </FormItem>
                <FormItem label="X轴偏移量（px）">
                  <a-input
                    v-model:value="formState.second_posx"
                    placeholder="请输入"
                    style="width:320px;"
                  />
                </FormItem>
                <FormItem label="Y轴偏移量（px）">
                  <a-input
                    v-model:value="formState.second_posy"
                    placeholder="请输入"
                    style="width:320px;"
                  />
                </FormItem>
              </div>
            </div>
          </div>
          <div class="right">
            <p
              v-if="$route.query.id"
              v-html="formState.content"
              class="content-info"
            ></p>
            <FormItem label="上传合同模板" name="content" v-else>
              <Tinymce
                class="pb-4"
                @change="handleChange"
                width="100%"
                height="446px"
                menubar="file edit view insert tools table help"
                useToolbar="formatselect alignleft aligncenter alignright | fontsizeselect | bold italic underline removeformat| table link image media lists fullscreen quickbars code"
                usePlugins="link image media table lists fullscreen quickbars code"
              />
            </FormItem>
          </div>
        </div>

        <FormItem class="mt-32" :wrapper-col="{ span: 8, offset: 10 }">
          <a-button v-if="step > 1" @click="step--">上一步</a-button>
          <a-button v-else @click="$router.back()">返回</a-button>
          <a-button
            v-if="step < 3"
            class="ml-16"
            type="primary"
            @click="handleNextStep"
            :loading="isLoading"
            >下一步</a-button
          >
          <a-button
            v-else
            class="ml-16"
            type="primary"
            @click="handleSubmit"
            :loading="isLoading"
            >完成</a-button
          >
        </FormItem>
      </Form>
    </div>

    <!-- 离开页面确认框 -->
    <w-modal
      :w="404"
      ref="modal"
      :closable="false"
      @handleOk="handleOk"
      centered
      okText="离开"
      cancelText="留下"
    >
      <div>
        <div class="d-flex justify-center">
          <Icon name="iconGroup282" height="16" width="16"></Icon>
          <p class="fz-16 fw-600 ml-8 lh-18">提示</p>
        </div>
        <p class="text-center mt-16">
          离开此页面后，已填内容无法保存，是否确认离开
        </p>
      </div>
      <template v-slot:footer="{ okText, handleOk, handleCancel, cancelText }">
        <a-button @click="handleOk">{{ okText }}</a-button>
        <a-button type="primary" @click="handleCancel">{{
          cancelText
        }}</a-button>
      </template>
    </w-modal>
  </div>
</template>

<script>
import { ref, toRaw, computed } from "vue";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import { Form, Radio, DatePicker, message } from "ant-design-vue";
import { Tinymce } from "@/components/tinymce/index";
import { getInvoiceRules, specialScript } from "./config";
import $await from "@/utils/await";
import moment from "moment";

import {
  useContractTemplateCreateApi,
  useContractTemplateUpdateApi,
  useContractDetailApi
} from "@/apis/contract";

export default {
  name: "add-tax-source",
  components: {
    Form,
    FormItem: Form.Item,
    Radio,
    RadioGroup: Radio.Group,
    DatePicker,
    Tinymce
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const contractTemplateCreateApi = useContractTemplateCreateApi();
    const contractTemplateUpdateApi = useContractTemplateUpdateApi();
    const contractDetailApi = useContractDetailApi();

    const formRef = ref();
    const isLoading = ref(false);

    const modalRef = ref();
    const step = ref(1);
    const ifLeave = ref(false);

    const Components = computed(() => `Component${step.value}`);

    const formState = ref({
      name: "",
      content: "",
      frequency: "ONCE",
      time_type: "1",
      effective_time: moment(),
      signed_object: "BOTH",
      code: "",
      placeholder_array: [],
      first_keyword: "",
      first_posx: "",
      first_posy: "",
      second_keyword: "",
      second_posx: "",
      second_posy: ""
    });

    (async () => {
      if (route.query.id) {
        const [, res] = await $await(contractDetailApi({ id: route.query.id }));
        if (res) {
          formState.value.name = res.name;
          formState.value.content = res.content;
          formState.value.frequency = res.frequency;
          formState.value.code = res.code;
          formState.value.placeholder_array = res.placeholder_array;
          formState.value.signed_object = res.signed_object;
          formState.value.first_keyword = res.ext_json.first_keyword;
          formState.value.first_posx = res.ext_json.first_posx;
          formState.value.first_posy = res.ext_json.first_posy;
          formState.value.second_keyword = res.ext_json.second_keyword;
          formState.value.second_posx = res.ext_json.second_posx;
          formState.value.second_posy = res.ext_json.second_posy;
          formState.value.time_type = "2";
          formState.value.effective_time = moment(res.effective_time);
        }
      }
    })();

    const rules = getInvoiceRules(formState);

    const disabledRangeTime = current => {
      const disableTimeValue = moment().subtract(1, "days");
      return current < disableTimeValue;
    };

    // 完成按钮点击
    const handleSubmit = () => {
      // 数据格式处理
      formState.value.ext_json = {
        first_keyword: formState.value.first_keyword,
        first_posx: formState.value.first_posx,
        first_posy: formState.value.first_posy,
        second_keyword: formState.value.second_keyword,
        second_posx: formState.value.second_posx,
        second_posy: formState.value.second_posy,
        signed_object: formState.value.signed_object
      };

      // 编辑
      if (route.query.id) {
        const data = {
          ...toRaw(formState.value),
          effective_time:
            formState.value.time_type === "1"
              ? moment().valueOf()
              : formState.value.effective_time.valueOf(),
          id: route.query.id
        };
        formRef.value
          .validate()
          .then(async () => {
            isLoading.value = true;
            const [, res] = await $await(contractTemplateUpdateApi(data));
            if (res) {
              isLoading.value = false;
              message.success("修改成功！");
              handleOk();
            } else {
              isLoading.value = false;
            }
          })
          .catch(error => {
            console.log("error", error);
          });
      } else {
        // 发布
        const data = {
          ...toRaw(formState.value),
          effective_time:
            formState.value.time_type === "1"
              ? moment().valueOf()
              : formState.value.effective_time.valueOf()
        };
        formRef.value
          .validate()
          .then(async () => {
            isLoading.value = true;
            const [, res] = await $await(contractTemplateCreateApi(data));
            if (res) {
              isLoading.value = false;
              message.success("合同模板已新建完成!");
              handleOk();
            } else {
              isLoading.value = false;
            }
          })
          .catch(error => {
            console.log("error", error);
          });
      }
    };

    // 下一步
    const handleNextStep = async () => {
      formRef.value.validate().then(() => {
        console.log(formState.value);
        step.value++;
      });
    };

    // 模板名称改变
    const handleNameChange = e => {
      console.log(e.target.value);
      formState.value.name = specialScript(e.target.value);
    };
    // 模板内容改变
    const handleChange = value => {
      formState.value.content = value;
    };

    // 添加占位符
    const handleAddPlaceHolder = () => {
      formState.value.placeholder_array.push({ key: "", value: "" });
    };

    // 返回
    // const handleCancel = () => {
    //   modalRef.value.handleCancel();
    //   router.back();
    // };

    // 确认返回
    const handleOk = () => {
      ifLeave.value = true;
      router.back();
    };

    // 离开页面前确认
    onBeforeRouteLeave(() => {
      if (!ifLeave.value) {
        modalRef.value.initShow();
        return false;
      }
    });

    return {
      rules,
      formRef,
      isLoading,
      formState,
      disabledRangeTime,
      handleChange,
      handleNameChange,
      handleSubmit,
      handleNextStep,
      Components,
      step,
      modal: modalRef,
      handleOk,
      handleAddPlaceHolder
    };
  }
};
</script>

<style lang="less" scoped>
#addTaxSource {
  background: #f4f4f4;
  padding: 16px 40px;
  .title {
    display: flex;
    align-items: center;
  }
  .content {
    border-radius: 5px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
    min-height: 642px;
    background: #fff;
    position: relative;
    .form-box {
      overflow: hidden;
    }
    .form-content {
      overflow: hidden;
      display: flex;
      border-bottom: 1px solid #d4d4d4;
      min-height: 564px;
      .left {
        border-right: 1px solid #d4d4d4;
        width: 420px;
        padding: 32px 0 0 64px;
        .input-grey {
          width: 320px;
          background: #f5f5f5;
          border-radius: 4px;
          padding-left: 8px;
          line-height: 32px;
        }
        .placeholder-list {
          li {
            display: flex;
            align-items: center;
            input {
              width: 144px;
            }
            .icon {
              margin: 0 8px;
            }
          }
        }
      }
      .right {
        flex: 1;
        padding: 32px;
        overflow: auto;
        .content-info {
          max-height: 446px;
          word-wrap: break-word;
        }
      }
    }
  }
}
</style>
