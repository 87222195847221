export const basicProps = {
  menubar: {
    type: String,
    default: "file edit insert view format table"
  },
  value: {
    type: String
    // default: ''
  },
  modelValue: {
    type: String
    // default: ''
  },
  // 高度
  height: {
    type: [Number, String],
    required: false,
    default: 400
  },
  // 自定义头部
  useToolbar: {
    type: String,
    required: false,
    default: ""
  },
  usePlugins: {
    type: String,
    required: false,
    default: "table"
  },
  // 宽度
  width: {
    type: [Number, String],
    required: false,
    default: "auto"
  }
};
