// import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    name: [
      {
        required: true,
        trigger: "blur",
        message: "合同模板名称不能为空"
      },
      {
        max: 30,
        message: "最多输入30个字符",
        trigger: "blur"
      }
    ],
    effective_time: [
      {
        required: true,
        message: "请选择时间",
        trigger: "change",
        type: "object"
      }
    ],
    code: [
      {
        required: true,
        trigger: "blur",
        message: "合同编码不能为空"
      }
    ],
    signed_object: [
      {
        required: true,
        trigger: "blur",
        message: "请选择签署对象"
      }
    ],
    content: [
      {
        required: true,
        trigger: "blur",
        message: "上传合同模板不能为空"
      }
    ],
    first_keyword: [
      {
        required: true,
        trigger: "blur",
        message: "甲方盖章区域关键字不能为空"
      }
    ],
    second_keyword: [
      {
        required: true,
        trigger: "blur",
        message: "乙方盖章区域关键字不能为空"
      }
    ]
  };

  return rules;
};

// 过滤输入框里的特殊字符
export const specialScript = value => {
  // eslint-disable-next-line no-useless-escape
  return value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, "");
};
